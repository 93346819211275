import { Title } from './section';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from './button';
import Video from './video';
import {
  BESTE_JEFFER_URL,
  GOODMANLILLEY_URL,
  GREENWOODS_URL,
  PETER_ELLIS_URL,
  RG_PROPERTY_URL,
  SYZO_LTD_URL
} from '@/config-global';

import React, { useEffect, useState } from 'react';

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  const [isMobile, setIsMobile] = useState(false);
  const [sliderWidth, setSliderWidth] = useState(0); // 存储 slider 的宽度

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 480);

      const sliderElements = document.getElementsByClassName('yourSliderId');
      if (sliderElements.length > 0) {
        const sliderElement = sliderElements[0] as HTMLElement;
        if (sliderElement) {
          setSliderWidth(sliderElement.offsetWidth);
        }
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isMobile) {
    return (
      <div
        className={`${className} bg-gray-500`}
        style={{
          position: 'absolute',
          top: '50%',
          left: sliderWidth - 40, // 根据 slider 宽度调整
          transform: 'translateY(-50%)',
          ...style
        }}
        onClick={onClick}
      />
    );
  } else {
    return (
      <div
        className={`${className} bg-gray-500`}
        style={{
          position: 'absolute',
          right: '2px',
          ...style
        }}
        onClick={onClick}
      />
    );
  }
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isMobile) {
    return (
      <div
        className={`${className} bg-gray-500 top: 50%; left: 50%; transform: translate(-50%, -50%); absolute z-10 size-10 rounded-full p-2 sm:size-8 sm:p-1.5 md:size-6 md:p-1`}
        style={{ ...style, marginLeft: '40px' }}
        onClick={onClick}
      />
    );
  } else {
    return (
      <div
        className={`${className} bg-gray-500 absolute left-2 z-10 size-10 rounded-full p-2 sm:size-8 sm:p-1.5 md:size-6 md:p-1`}
        style={style}
        onClick={onClick}
      />
    );
  }
}

export default function Logos() {
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 5000,
    slidesToShow: 5,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    arrows: false,
    pauseOnHover: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

  const _settings = {
    infinite: false,
    autoplay: false,
    slidesToShow: 1,
    cssEase: 'linear',
    pauseOnHover: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true
        }
      }
    ]
  };

  return (
    <div className="bg-gray-900 py-24 sm:py-32" id="link_logos">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <Title
          text="Trusted by agencies"
          className="mx-auto w-full max-w-screen-lg font-medium"
        />
        <Slider {...settings} className="mt-16">
          <div>
            <img
              className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
              src="/assets/logos/1.png"
              alt="Cliftons&Butlers"
              width={158}
              height={64}
            />
          </div>
          <div>
            <img
              className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
              src="/assets/logos/2.png"
              alt="BesteJeffers"
              width={158}
              height={48}
            />
          </div>
          {/* <div>
            <img
              className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
              src="/assets/logos/3.png"
              alt="Bristol SU Lettings"
              width={158}
              height={48}
            />
          </div> */}
          <div>
            <img
              className="col-span-2 col-start-2 h-6 max-h-16 w-full object-contain sm:col-start-auto md:h-10 lg:col-span-1"
              src="/assets/logos/4.png"
              alt="iletpro"
              width={128}
              height={28}
            />
          </div>
          {/* <div>
            <img
              className="col-span-2 col-start-2 max-h-20 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="/assets/logos/5.png"
              alt="Missiato estates"
              width={158}
              height={48}
            />
          </div> */}
          <div>
            <img
              className="col-span-2 col-start-2 h-8 max-h-20 w-full object-contain sm:col-start-auto md:h-12 lg:col-span-1"
              src="/assets/logos/6.png"
              alt="Accommodation unltd"
              width={138}
              height={36}
            />
          </div>
          <div>
            <img
              className="col-span-2 col-start-2 h-14 max-h-20 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="/assets/logos/7.png"
              alt="RG property"
              width={158}
              height={48}
            />
          </div>
          <div>
            <img
              className="col-span-2 col-start-2 h-14 max-h-20 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="/assets/logos/goodmanLilley.png"
              alt="The goodman lilley"
              width={158}
              height={48}
            />
          </div>
          <div>
            <img
              className="col-span-2 col-start-2 h-14 max-h-20 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="/assets/logos/greenwoods.png"
              alt="The greenwoods"
              width={158}
              height={48}
            />
          </div>
          {/* <div>
            <img
              className="col-span-2 col-start-2 h-14 max-h-20 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="/assets/logos/8.png"
              alt="The letting shop"
              width={158}
              height={48}
            />
          </div> */}
        </Slider>
      </div>
      <div className="container mx-auto w-full max-w-[1467px]">
        <Slider
          {..._settings}
          className="yourSliderId mx-auto mt-16 w-full md:w-1/2"
        >
          {/*Greenwoods Slider*/}
          <div>
            <div className="border-darkslateblue mq450:pl-[1.111rem] mq450:box-border relative box-border flex min-h-[400px] w-auto flex-col items-start justify-start gap-[0.741rem] overflow-hidden rounded-[30px] pb-[2.722rem] pl-[1.6rem]  pr-[1.611rem] pt-[1.333rem] text-left font-poppins text-[0.939rem] leading-[normal] tracking-[normal] text-white mix-blend-normal [background:linear-gradient(180deg,_rgba(16,_168,_227,_0.4),_rgba(27,_0,_106,_0.4))] xs:mt-12 md:mx-4 md:rounded-[72px] md:pl-[2.444rem]">
              <img
                className="relative mt-3 h-[1.7rem] w-[2.6rem] md:h-[3.444rem] md:w-[5.611rem]"
                loading="lazy"
                alt=""
                src="/vector.svg"
              />
              <div className="justify-between md:flex md:space-x-4">
                <div className="space-y-4">
                  <section className="relative inline-block max-w-full text-left font-poppins font-medium leading-[1.6rem] tracking-[-0.1px] text-white md:text-[1.867rem] md:font-light md:leading-[2.222rem]">
                    <p className="m-0 text-3xl">
                      Improved efficiency and better customer services by using
                      Nestflo.
                    </p>
                  </section>
                  <div className="relative flex max-w-full flex-1 items-center font-light leading-[1.333rem] tracking-[-0.1px]">
                    What Nestflo does is that it strikes while the irons hot, so
                    an applicant could request a viewing via Rightmove at 9 pm.
                    Nestflo for us is given that real base level of customer
                    service that initiate a conversation with a customer and
                    which is with benefits obviously us as a company and having
                    that engagement piece. The reasons I recommend Nestflo to
                    other agencies are enhanced efficiency, improved customer
                    service, and increased conversion rates.
                  </div>
                  <div className="mq500:flex-wrap flex flex-row items-end justify-between gap-[1.111rem] self-stretch text-[1.222rem] md:pt-4">
                    <b className="mq450:leading-[1.778rem] relative text-[0.8rem] leading-[1.2rem] tracking-[-0.1px] md:text-[1rem] md:leading-[2.222rem]">
                      Aaron Messenger - The lettings manager of &nbsp;
                      <a
                        href="https://www.greenwoodsproperty.co.uk/meet-the-team"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: 'underline'
                        }}
                      >
                        Greenwoods Property Centre
                      </a>
                    </b>
                  </div>
                </div>
                <div className="mt-8 shrink-0 flex-col items-end md:-mt-10 md:flex">
                  <Video
                    target={
                      <img
                        className="relative w-24 md:w-64"
                        loading="lazy"
                        alt=""
                        src="/assets/customer_avatar/Greenwoods.png"
                      />
                    }
                    url={GREENWOODS_URL}
                  />
                  <Video
                    target={
                      <Button className="shadow-gradient mt-12 w-full bg-gradient-to-r from-blue via-brown to-orange px-8">
                        Watch Video Testimonial
                      </Button>
                    }
                    url={GREENWOODS_URL}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*goodman Lilley Slider*/}
          <div>
            <div className="border-darkslateblue mq450:pl-[1.111rem] mq450:box-border relative box-border flex min-h-[400px] w-auto flex-col items-start justify-start gap-[0.741rem] overflow-hidden rounded-[30px] pb-[2.722rem] pl-[1.6rem]  pr-[1.611rem] pt-[1.333rem] text-left font-poppins text-[0.939rem] leading-[normal] tracking-[normal] text-white mix-blend-normal [background:linear-gradient(180deg,_rgba(16,_168,_227,_0.4),_rgba(27,_0,_106,_0.4))] xs:mt-12 md:mx-4 md:rounded-[72px] md:pl-[2.444rem]">
              <img
                className="relative mt-3 h-[1.7rem] w-[2.6rem] md:h-[3.444rem] md:w-[5.611rem]"
                loading="lazy"
                alt=""
                src="/vector.svg"
              />
              <div className="justify-between md:flex md:space-x-4">
                <div className="space-y-4">
                  <section className="relative inline-block max-w-full text-left font-poppins font-medium leading-[1.6rem] tracking-[-0.1px] text-white md:text-[1.867rem] md:font-light md:leading-[2.222rem]">
                    <p className="m-0 text-3xl">
                      To be honest, it&apos;s one of the better moves I&apos;ve
                      probably made in the last couple of years!
                    </p>
                  </section>
                  <div className="relative flex max-w-full flex-1 items-center font-light leading-[1.333rem] tracking-[-0.1px]">
                    I think it&apos;s brilliant, to be fair. However, I must
                    admit I was skeptical. We have an awful lot of technology
                    companies that come and ask us to use their services. With
                    this one (Nestflo), I felt the same way.But I was
                    recommended by another agent and told to have a look at it,
                    and I did. Tobe honest, it&apos;s one of the better moves
                    I&apos;ve probably made in the last couple of years.
                  </div>
                  <div className="mq500:flex-wrap flex flex-row items-end justify-between gap-[1.111rem] self-stretch text-[1.222rem] md:pt-4">
                    <b className="mq450:leading-[1.778rem] relative text-[0.8rem] leading-[1.2rem] tracking-[-0.1px] md:text-[1rem] md:leading-[2.222rem]">
                      John Wootton - The Head of Land & Operations of&nbsp;
                      <a
                        href="https://www.goodmanlilley.co.uk/meet-the-team.html"
                        target="_blank"
                        style={{
                          textDecoration: 'underline'
                        }}
                        rel="noreferrer"
                      >
                        Goodman Lilley
                      </a>
                    </b>
                  </div>
                </div>
                <div className="mt-8 shrink-0 flex-col items-end md:-mt-10 md:flex">
                  <Video
                    target={
                      <img
                        className="relative w-24 md:w-64"
                        loading="lazy"
                        alt=""
                        src="/assets/customer_avatar/goodmanlilley.png"
                      />
                    }
                    url={GOODMANLILLEY_URL}
                  />
                  <Video
                    target={
                      <Button className="shadow-gradient mt-12 w-full bg-gradient-to-r from-blue via-brown to-orange px-8">
                        Watch Video Testimonial
                      </Button>
                    }
                    url={GOODMANLILLEY_URL}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*Syzo Ltd Slider*/}
          <div className="">
            <div className="border-darkslateblue mq450:pl-[1.111rem] mq450:box-border relative box-border flex min-h-[400px] w-auto flex-col items-start justify-start gap-[0.741rem] overflow-hidden rounded-[30px] pb-[2.722rem] pl-[1.6rem]  pr-[1.611rem] pt-[1.333rem] text-left font-poppins text-[0.939rem] leading-[normal] tracking-[normal] text-white mix-blend-normal [background:linear-gradient(180deg,_rgba(16,_168,_227,_0.4),_rgba(27,_0,_106,_0.4))] xs:mt-12 md:mx-4 md:rounded-[72px] md:pl-[2.444rem]">
              <img
                className="relative mt-3 h-[1.7rem] w-[2.6rem] md:h-[3.444rem] md:w-[5.611rem]"
                loading="lazy"
                alt=""
                src="/vector.svg"
              />
              <div className="justify-between md:flex md:space-x-4">
                <div className="space-y-4">
                  <section className="relative inline-block max-w-full text-left font-poppins font-medium leading-[1.6rem] tracking-[-0.1px] text-white md:text-[1.867rem] md:font-light md:leading-[2.222rem]">
                    <p className="m-0 text-3xl">
                      Nestflo has really improved our sales ability.
                    </p>
                  </section>
                  <div className="relative flex max-w-full flex-1 items-center font-light leading-[1.333rem] tracking-[-0.1px]">
                    I think the big driver for us is reducing headcount, and if
                    we can keep the number of employees down, it increases our
                    bottom line, which leads to better profits and a stronger
                    business. This means more money to reinvestin the business
                    for growth, and less management required for the existing
                    team. So yeah, that would be the biggest benefit for me.
                  </div>
                  <div className="mq500:flex-wrap flex flex-row items-end justify-between gap-[1.111rem] self-stretch text-[1.222rem] md:pt-4">
                    <b className="mq450:leading-[1.778rem] relative text-[0.8rem] leading-[1.2rem] tracking-[-0.1px] md:text-[1rem] md:leading-[2.222rem]">
                      Dom Munson - The Foudner and CEO of &nbsp;
                      <a
                        href="https://www.syzo.co/about"
                        target="_blank"
                        style={{
                          textDecoration: 'underline'
                        }}
                        rel="noreferrer"
                      >
                        Syzo Ltd
                      </a>
                    </b>
                  </div>
                </div>
                <div className="mt-8 shrink-0 flex-col items-end md:-mt-10 md:flex">
                  <Video
                    target={
                      <img
                        className="relative w-24 cursor-pointer md:w-64"
                        loading="lazy"
                        alt=""
                        src="/assets/customer_avatar/syzo.png"
                      />
                    }
                    url={SYZO_LTD_URL}
                  />
                  <Video
                    target={
                      <Button className="shadow-gradient mt-12 w-full bg-gradient-to-r from-blue via-brown to-orange px-8">
                        Watch Video Testimonial
                      </Button>
                    }
                    url={SYZO_LTD_URL}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*RG Property Slider*/}
          <div>
            <div className="border-darkslateblue mq450:pl-[1.111rem] mq450:box-border relative box-border flex min-h-[400px] w-auto flex-col items-start justify-start gap-[0.741rem] overflow-hidden rounded-[30px] pb-[2.722rem] pl-[1.6rem]  pr-[1.611rem] pt-[1.333rem] text-left font-poppins text-[0.939rem] leading-[normal] tracking-[normal] text-white mix-blend-normal [background:linear-gradient(180deg,_rgba(16,_168,_227,_0.4),_rgba(27,_0,_106,_0.4))] xs:mt-12 md:mx-4 md:rounded-[72px] md:pl-[2.444rem]">
              <img
                className="relative mt-3 h-[1.7rem] w-[2.6rem] md:h-[3.444rem] md:w-[5.611rem]"
                loading="lazy"
                alt=""
                src="/vector.svg"
              />
              <div className="justify-between md:flex md:space-x-4">
                <div className="space-y-4">
                  <section className="relative inline-block max-w-full text-left font-poppins font-medium leading-[1.6rem] tracking-[-0.1px] text-white md:text-[1.867rem] md:font-light md:leading-[2.222rem]">
                    <p className="m-0 text-3xl">
                      We&apos;ve saved a lot of time.
                    </p>
                  </section>
                  <div className="relative flex max-w-full flex-1 items-center font-light leading-[1.333rem] tracking-[-0.1px]">
                    We are impressed with how quickly inquiries come through,
                    providing concise summaries that include whether
                    they&apos;ve passed a score. We&apos;ve saved a lot of time
                    booking viewings without having to repeatedly ask the same
                    questions.
                  </div>
                  <div className="mq500:flex-wrap flex flex-row items-end justify-between gap-[1.111rem] self-stretch text-[1.222rem] md:pt-4">
                    <b className="mq450:leading-[1.778rem] relative text-[0.8rem] leading-[1.2rem] tracking-[-0.1px] md:text-[1rem] md:leading-[2.222rem]">
                      Matthew Ryder & Ben Gregory - The founders of{' '}
                      <a
                        href="https://www.rgproperty.uk/"
                        target="_blank"
                        style={{
                          textDecoration: 'underline'
                        }}
                        rel="noreferrer"
                      >
                        RG Property
                      </a>
                    </b>
                  </div>
                </div>
                <div className="mt-8 shrink-0 flex-col items-end md:-mt-10 md:flex">
                  <Video
                    target={
                      <img
                        className="relative w-24 md:w-64"
                        loading="lazy"
                        alt=""
                        src="/assets/customer_avatar/R&G_Property.png"
                      />
                    }
                    url={RG_PROPERTY_URL}
                  />
                  <Video
                    target={
                      <Button className="shadow-gradient mt-12 w-full bg-gradient-to-r from-blue via-brown to-orange px-8">
                        Watch Video Testimonial
                      </Button>
                    }
                    url={RG_PROPERTY_URL}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*Beste Jeffers Slider*/}
          <div className="">
            <div className="border-darkslateblue mq450:pl-[1.111rem] mq450:box-border relative box-border flex min-h-[400px] w-auto flex-col items-start justify-start gap-[0.741rem] overflow-hidden rounded-[30px] pb-[2.722rem] pl-[1.6rem]  pr-[1.611rem] pt-[1.333rem] text-left font-poppins text-[0.939rem] leading-[normal] tracking-[normal] text-white mix-blend-normal [background:linear-gradient(180deg,_rgba(16,_168,_227,_0.4),_rgba(27,_0,_106,_0.4))] xs:mt-12 md:mx-4 md:rounded-[72px] md:pl-[2.444rem]">
              <img
                className="relative mt-3 h-[1.7rem] w-[2.6rem] md:h-[3.444rem] md:w-[5.611rem]"
                loading="lazy"
                alt=""
                src="/vector.svg"
              />
              <div className="justify-between md:flex md:space-x-4">
                <div className="space-y-4">
                  <section className="relative inline-block max-w-full text-left font-poppins font-medium leading-[1.6rem] tracking-[-0.1px] text-white md:text-[1.867rem] md:font-light md:leading-[2.222rem]">
                    <p className="m-0 text-3xl">
                      Nestflo improves agency productivity and reduce workload.
                    </p>
                  </section>
                  <div className="relative flex max-w-full flex-1 items-center font-light leading-[1.333rem] tracking-[-0.1px]">
                    Nestflo is very good for agencies with a high volume of
                    inquiries, as it can save a lot of time, boost productivity,
                    and reduce unnecessary workload.
                  </div>
                  <div className="mq500:flex-wrap flex flex-row items-end justify-between gap-[1.111rem] self-stretch text-[1.222rem] md:pt-4">
                    <b className="mq450:leading-[1.778rem] relative text-[0.8rem] leading-[1.2rem] tracking-[-0.1px] md:text-[1rem] md:leading-[2.222rem]">
                      Pak Liang - The lettings manager of &nbsp;
                      <a
                        href="https://beste-jeffers.cosyflo.co.uk"
                        target="_blank"
                        style={{
                          textDecoration: 'underline'
                        }}
                        rel="noreferrer"
                      >
                        Beste Jeffers Ltd
                      </a>
                    </b>
                  </div>
                </div>
                <div className="mt-8 shrink-0 flex-col items-end md:-mt-10 md:flex">
                  <Video
                    target={
                      <img
                        className="relative w-24 cursor-pointer md:w-64"
                        loading="lazy"
                        alt=""
                        src="/assets/customer_avatar/Beste_Jeffers.png"
                      />
                    }
                    url={BESTE_JEFFER_URL}
                  />
                  <Video
                    target={
                      <Button className="shadow-gradient mt-12 w-full bg-gradient-to-r from-blue via-brown to-orange px-8">
                        Watch Video Testimonial
                      </Button>
                    }
                    url={BESTE_JEFFER_URL}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*Peter Ellis*/}
          <div className="">
            <div className="border-darkslateblue mq450:pl-[1.111rem] mq450:box-border relative box-border flex min-h-[400px] w-auto flex-col items-start justify-start gap-[0.741rem] overflow-hidden rounded-[30px] pb-[2.722rem] pl-[1.6rem]  pr-[1.611rem] pt-[1.333rem] text-left font-poppins text-[0.939rem] leading-[normal] tracking-[normal] text-white mix-blend-normal [background:linear-gradient(180deg,_rgba(16,_168,_227,_0.4),_rgba(27,_0,_106,_0.4))] xs:mt-12 md:mx-4 md:rounded-[72px] md:pl-[2.444rem]">
              <img
                className="relative mt-3 h-[1.7rem] w-[2.6rem] md:h-[3.444rem] md:w-[5.611rem]"
                loading="lazy"
                alt=""
                src="/vector.svg"
              />
              <div className="justify-between md:flex md:space-x-4">
                <div className="space-y-4">
                  <section className="relative inline-block max-w-full text-left font-poppins font-medium leading-[1.6rem] tracking-[-0.1px] text-white md:text-[1.867rem] md:font-light md:leading-[2.222rem]">
                    <p className="m-0 text-3xl">It&apos; pretty positive!</p>
                  </section>
                  <div className="relative flex max-w-full flex-1 items-center font-light leading-[1.333rem] tracking-[-0.1px]">
                    After a few initial teasing problems, I find it really easy
                    to use. We can contact the best quality tenants in advance
                    that rather than doing them in data order.
                  </div>
                  <div className="mq500:flex-wrap flex flex-row items-end justify-between gap-[1.111rem] self-stretch text-[1.222rem] md:pt-4">
                    <b className="mq450:leading-[1.778rem] relative text-[0.8rem] leading-[1.2rem] tracking-[-0.1px] md:text-[1rem] md:leading-[2.222rem]">
                      Peter Ellis - Owner of &nbsp;
                      <a
                        href="https://aul.org.uk/"
                        target="_blank"
                        style={{
                          textDecoration: 'underline'
                        }}
                        rel="noreferrer"
                      >
                        Accommodation Unlimited ltd
                      </a>
                    </b>
                  </div>
                </div>
                <div className="mt-8 shrink-0 flex-col items-end md:-mt-10 md:flex">
                  <Video
                    target={
                      <img
                        className="relative w-24 cursor-pointer md:w-64"
                        loading="lazy"
                        alt=""
                        src="/assets/customer_avatar/Accommodation_Unlimited.png"
                      />
                    }
                    url={PETER_ELLIS_URL}
                  />
                  <Video
                    target={
                      <Button className="shadow-gradient mt-12 w-full bg-gradient-to-r from-blue via-brown to-orange px-8">
                        Watch Video Testimonial
                      </Button>
                    }
                    url={PETER_ELLIS_URL}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*Beste Estates Slider*/}
          <div className="">
            <div className="border-darkslateblue mq450:pl-[1.111rem] mq450:box-border relative box-border flex min-h-[400px] w-auto flex-col items-start justify-start gap-[0.741rem] overflow-hidden rounded-[30px] pb-[2.722rem] pl-[1.6rem]  pr-[1.611rem] pt-[1.333rem] text-left font-poppins text-[0.939rem] leading-[normal] tracking-[normal] text-white mix-blend-normal [background:linear-gradient(180deg,_rgba(16,_168,_227,_0.4),_rgba(27,_0,_106,_0.4))] xs:mt-12 md:mx-4 md:rounded-[72px] md:pl-[2.444rem]">
              <img
                className="relative mt-3 h-[1.7rem] w-[2.6rem] md:h-[3.444rem] md:w-[5.611rem]"
                loading="lazy"
                alt=""
                src="/vector.svg"
              />
              <div className="justify-between md:flex md:space-x-4">
                <div className="space-y-4">
                  <section className="relative inline-block max-w-full text-left font-poppins font-medium leading-[1.6rem] tracking-[-0.1px] text-white md:text-[1.867rem] md:font-light md:leading-[2.222rem]">
                    <p className="m-0 text-3xl">
                      Every member of staff at my two agencies loves Nestflo.
                    </p>
                  </section>
                  <div className="relative flex max-w-full flex-1 items-center font-light leading-[1.333rem] tracking-[-0.1px]">
                    Every member of staff at my two agencies loves the Nestflo
                    solution because it saves them time. It does this by
                    automatically vetting prospective tenants 24/7 into a report
                    with weighted scores and grades, thus, identifying the best
                    tenants for a property.
                  </div>
                  <div className="mq500:flex-wrap flex flex-row items-end justify-between gap-[1.111rem] self-stretch text-[1.222rem] md:pt-4">
                    <b className="mq450:leading-[1.778rem] relative text-[0.8rem] leading-[1.2rem] tracking-[-0.1px] md:text-[1rem] md:leading-[2.222rem]">
                      Philip Stolworthy - The owners of &nbsp;
                      <a
                        href="https://www.besteestates.co.uk/"
                        target="_blank"
                        style={{
                          textDecoration: 'underline'
                        }}
                        rel="noreferrer"
                      >
                        Beste Estates & Beste Jeffers ltd.
                      </a>
                    </b>
                  </div>
                </div>
                <div className="mt-8 shrink-0 flex-col items-end md:-mt-10 md:flex">
                  <img
                    className="relative w-24 cursor-pointer md:w-64"
                    loading="lazy"
                    alt=""
                    src="/logo-bg.png"
                  />
                  {/*<Video*/}
                  {/*  target={*/}
                  {/*    <img*/}
                  {/*      className="relative w-24 cursor-pointer md:w-64"*/}
                  {/*      loading="lazy"*/}
                  {/*      alt=""*/}
                  {/*      src="/logo-bg.png"*/}
                  {/*    />*/}
                  {/*  }*/}
                  {/*  url={BESTE_JEFFER_lTD_URL}*/}
                  {/*/>*/}
                  {/*<Video*/}
                  {/*  target={*/}
                  {/*    <Button className="shadow-gradient mt-12 w-full bg-gradient-to-r from-blue via-brown to-orange px-8">*/}
                  {/*      Watch Video Testimonial*/}
                  {/*    </Button>*/}
                  {/*  }*/}
                  {/*  url={BESTE_JEFFER_lTD_URL}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
