import { FC, useEffect, useState } from 'react';
import useShowMore from '@/utils/useShowMore';
import Button from './button';
import Video from './video';
import { useResponsive } from '@/utils/useResponsive';
import { BOOK_DEMO_URL, VIDEO_URL } from '@/config-global';

const text =
  'Nestflo AI-powered virtual robot automates repetitive tasks, empowers fast response times, and captures new business opportunities for estate agencies.';

const Hero: FC = () => {
  const [limit, setLimit] = useState(0);
  const { toggle, isMore } = useShowMore(text, limit);
  const { isMobile } = useResponsive();
  const [, setTooltipOpen] = useState(false); // For Tooltip control

  useEffect(() => {
    if (isMobile) {
      setLimit(40);
    } else {
      setLimit(70);
    }
  }, [isMobile]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setTooltipOpen(true); // Show tooltip when copy is successful
        setTimeout(() => {
          setTooltipOpen(false); // Hide tooltip after 2 seconds
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false); // Close tooltip on click away
  };

  const handleBookDemoClick = () => {
    window.open(BOOK_DEMO_URL, '_blank');
  };

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative">
        <picture className="pointer-events-none absolute inset-0 -z-20 overflow-hidden">
          <img
            src="/assets/background/bg_hero_375.png"
            alt="dark"
            className="h-auto w-full sm:hidden"
          />
          <video autoPlay loop muted className="hidden h-auto w-full sm:block">
            <source src="/assets/video/bg_hero.mp4" type="video/mp4" />
          </video>
        </picture>
        <div className="container pb-20 pt-16 xl:pt-32">
          <div className="mx-auto max-w-6xl pt-20 lg:pt-36">
            <div className="lg:w-4/5">
              <h1 className="font-display mb-10 text-4xl font-light leading-[50px] tracking-tighter text-white sm:text-[40px] md:text-[50px] lg:text-6xl lg:leading-[70px]">
                Automate client interaction
              </h1>
              <p
                className="text-sm font-medium leading-[28px] tracking-tighter sm:text-base lg:text-base"
                style={{
                  marginBottom: '40px'
                }}
              >
                <span className="align-bottom">{text}</span>
                <span
                  className="ml-2 hidden cursor-pointer align-bottom font-medium text-orange"
                  onClick={toggle}
                >
                  {isMore ? 'Show less' : 'Show more'}
                </span>
              </p>
              <div className="flex flex-col items-center justify-center space-y-4 lg:flex-row lg:justify-start lg:space-x-4 lg:space-y-0">
                <Button
                  className="bg-gradient-to-r from-blue via-brown to-orange"
                  style={{
                    width: '206px',
                    height: '52px'
                  }}
                  onClick={handleBookDemoClick} // 处理 Book Demo 按钮点击事件
                >
                  Book Demo
                </Button>

                <Video
                  target={
                    <Button
                      className="flex items-center bg-gradient-to-r from-blue via-brown to-orange"
                      style={{
                        background: 'rgba(255, 255, 255, 0.1)',
                        color: 'white',
                        opacity: 0.6,
                        zIndex: 1,
                        width: '206px',
                        height: '52px',
                        paddingLeft: '16px'
                      }}
                    >
                      {/* 添加播放图标 */}
                      <img
                        src="/assets/icons/play.svg"
                        alt="Play"
                        className="mr-2 size-6"
                      />
                      Watch video story
                    </Button>
                  }
                  url={VIDEO_URL}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
