import React, { useEffect, useState } from 'react';
import { BOOK_DEMO_URL } from '@/config-global';
import { useResponsive } from '@/utils/useResponsive';
import { WaveIcon } from '@/assets/icons';
import { Container, Cta, Section } from '@/components/section';
import Link from 'next/link';
const Example: React.FC = () => {
  const [bg, setBg] = useState('');

  const { isMobile } = useResponsive();

  useEffect(() => {
    if (isMobile) {
      setBg('/assets/background/bg_example_375.png');
    } else {
      setBg('/assets/background/bg_example.png');
    }
  }, [isMobile]);

  return (
    <Section className="hidden md:block">
      <Container className="max-w-[1174px]">
        <div className="relative">
          <picture className="pointer-events-none">
            <img src={bg} alt="dark" className="-mx-4 h-auto w-full lg:mx-0" />
          </picture>
          <picture className="pointer-events-none hidden sm:block">
            <div className="absolute bottom-[80px] h-auto sm:right-0 sm:w-[410px] md:bottom-[150px] md:w-[620px] lg:right-[-36px] lg:w-[820px]">
              <div className="relative">
                <img src="/assets/background/bg_example_1.png" alt="dark" />
                <WaveIcon className="absolute fill-white sm:left-[85px] sm:top-[25px] sm:w-[44px] md:left-[135px] md:top-[40px] md:w-[58px] lg:left-[170px] lg:top-[50px] lg:w-[88px]" />
                <WaveIcon className="absolute fill-darker sm:bottom-[37px] sm:left-[50px] sm:w-[44px] md:bottom-[56px] md:left-[80px] md:w-[58px] lg:bottom-[75px] lg:left-[100px] lg:w-[88px]" />
                <p className="vertical-center absolute right-0 w-1/2 pb-6 font-light tracking-tighter sm:text-sm lg:text-[28px] lg:leading-[34px]">
                  Intelligent autonomous replies not to ignore tenants’ initial
                  enquiries
                </p>
                <p className="gradient-text-orange absolute bottom-0 right-0 text-xl">
                  AI Interactive voice response (IVR)
                </p>
              </div>
            </div>
          </picture>
          <picture className="pointer-events-none">
            <img
              src="/assets/background/bg_example_2.png"
              alt="dark"
              className="vertical-center absolute left-0 h-auto w-full sm:max-w-[345px] md:max-w-[400px]  lg:left-[-80px] lg:max-w-[460px]"
            />
          </picture>
          <picture className="pointer-events-none hidden sm:block">
            <img
              src="/assets/background/bg_example_3.png"
              alt="dark"
              className="absolute top-[100px] h-auto sm:right-0 sm:w-[420px] md:w-[615px] lg:right-[-50px] lg:w-[820px]"
            />
          </picture>
        </div>

        <div className="flex w-full flex-col items-center">
          <Link href={BOOK_DEMO_URL} target="_blank">
            <Cta>Book a demo</Cta>
          </Link>
        </div>
      </Container>
    </Section>
  );
};

export default Example;
