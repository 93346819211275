import React from 'react';
import { Container, Content, Cta, Section, Title } from './section';
import {
  BellIcon,
  CalendarIcon,
  GearIcon,
  HourIcon,
  RiseIcon,
  RobotIcon,
  ScanIcon,
  SignalIcon,
  TalkIcon,
  UploadIcon
} from '@/assets/icons';
import { ProcessShadow } from '@/assets/shadow';
import Line from '@/components/line';
import { Card, Head, Paragraph, SubHead } from '@/components/card';
import Link from 'next/link';
import { BOOK_DEMO_URL } from '@/config-global';
interface DataOneProps {
  title: string;
  icon: React.ReactNode;
}

interface DataTwoProps {
  title: string;
  icon: React.ReactNode;
  subTitle: string;
  description: string;
}

const ItemOne: React.FC<{ item: DataOneProps }> = ({ item }) => {
  return (
    <div className="flex items-center rounded-3xl border border-sky/50 bg-darker/40 p-4 lg:justify-center xl:p-6">
      <div className="flex items-center">
        {item.icon}
        <h4 className="font-light leading-[27px] sm:text-lg lg:text-base xl:text-lg">
          {item.title}
        </h4>
      </div>
    </div>
  );
};

const ItemTwo: React.FC<{ item: DataTwoProps }> = ({ item }) => {
  return (
    <Card>
      <div className="absolute -left-8 top-6 h-1 w-36 bg-gradient-to-r from-[#2D009E] via-[#B3304A] to-[#EF4F34] lg:top-10" />
      <div className="absolute right-6 top-6 lg:right-10 lg:top-10">
        {item.icon}
      </div>
      {item.title && <Head className="mt-4 pr-14">{item.title}</Head>}
      {item.subTitle && <SubHead>{item.subTitle}</SubHead>}
      <Paragraph className="mt-6">{item.description}</Paragraph>
    </Card>
  );
};

const Process: React.FC = () => {
  const dataOne: DataOneProps[] = [
    {
      title: 'Automate repetitive tasks',
      icon: (
        <GearIcon className="mr-3 w-6 shrink-0 sm:w-[45px] lg:mr-4 xl:mr-6 xl:w-14" />
      )
    },
    {
      title: 'Instant responses to tenants’ enquiries',
      icon: (
        <RiseIcon className="mr-3 w-6 shrink-0 sm:w-[45px] lg:mr-4 xl:mr-6 xl:w-14" />
      )
    },
    {
      title: 'Engage with tenants 24/7',
      icon: (
        <HourIcon className="mr-3 w-6 shrink-0 sm:w-[45px] lg:mr-4 xl:mr-6 xl:w-14" />
      )
    },
    {
      title: 'Automatically reply with relevant data',
      icon: (
        <UploadIcon className="mr-3 w-6 shrink-0 sm:w-[45px] lg:mr-4 xl:mr-6 xl:w-14" />
      )
    }
  ];
  const dataTwo: DataTwoProps[] = [
    {
      title: 'Initial tenant enquiry from portals',
      subTitle: '',
      description:
        'Once a potential tenant submits an enquiry form with a specific message on Rightmove, our AI-powered virtual robot will utilize data to provide relevant, instant, and intelligent replies using generative AI capabilities.',
      icon: <CalendarIcon className="w-12 shrink-0 xl:w-14" />
    },
    {
      title: 'Automated customer Q&A services',
      subTitle: '',
      description:
        'When a tenant arrives at your customised Chatbot, the tenant can ask questions about a specific property. Nestflo AI Chatbot draws on data to respond instantly and intelligently.',
      icon: <TalkIcon className="w-12 shrink-0 xl:w-14" />
    },
    {
      title: 'Multi-level Vetting prospective tenants',
      subTitle: '',
      description:
        'Once the potential tenant expresses an interest in a property, the AI-powered virtual robot will ask customised vetting questions to tenants and summarise their responses into a vetting report with weighted scores and grades.',
      icon: <RobotIcon className="w-12 shrink-0 xl:w-14" />
    },
    {
      title: 'Intelligent scheduling management',
      subTitle: '',
      description: `Nestflo helps the prospective tenant book a viewing, using live access to the relevant letting agent’s diary. It will intelligently arrange a viewing to fit geographically and time-wise with other viewings.`,
      icon: <UploadIcon className="w-12 shrink-0 xl:w-14" />
    }
  ];
  return (
    <Section id="link_2">
      <picture className="pointer-events-none absolute inset-0 -z-20">
        <img
          src="/assets/background/bg_process.png"
          alt="dark"
          className="-z-10 h-auto w-full"
        />
        <Line variant="medium" className="z-10" />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-[-600px] -z-20 opacity-60">
        <ProcessShadow className="h-auto w-full" />
      </picture>
      <Container>
        <Title
          text="A range of automated services to let your staff focus on pre-qualified prospective tenants"
          className="mx-auto w-full max-w-screen-lg font-medium"
        />
        <Content>
          <div className="mx-auto grid w-full max-w-[1350px] grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-5 lg:grid-cols-4 lg:gap-8">
            {dataOne.map((item, index) => (
              <ItemOne item={item} key={index} />
            ))}
          </div>
          <div className="mt-10 space-y-8 lg:mt-20">
            <p className="mx-auto w-full max-w-[1350px] text-sm font-light leading-[28px] sm:text-base lg:text-xl lg:leading-[45px]">
              Handle all your tenant lead management on one platform, leveraging
              the power of AI and Large Language Models (LLMs). You&apos;ll
              enhance your ability to respond instantly to tenants with
              personal, tailored communications, integrated across email,
              AI-powered virtual assistants, and Interactive Voice Response
              (IVR) systems.
            </p>
          </div>
          <div className="mx-auto mt-10 w-full max-w-screen-lg lg:mt-20">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-5 lg:gap-12">
              {dataTwo.map((item, index) => (
                <ItemTwo item={item} key={index} />
              ))}
            </div>
          </div>
          <div className="flex w-full flex-col items-center">
            <Link href={BOOK_DEMO_URL} target="_blank">
              <Cta>Book a demo</Cta>
            </Link>
          </div>
        </Content>
      </Container>
    </Section>
  );
};

export default Process;
